@import "../../styles/variable.scss";

.solution_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  &__content {
    max-width: 1440;
    padding: 0px 150px;
    margin-bottom: 50px;
    &__info {
      display: flex;
      align-items: center;
      &__image {
        width: 570px;
        & > img {
          width: 100%;
        }
      }
      &__text {
        width: 516px;
        text-align: left;
        &__title {
          font-size: 35px;
          font-weight: 700;
          margin-bottom: 35px;
        }
        &__details {
          font-size: 20px;
          font-weight: 500;
        }
      }
    }
    &__cards {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 100px;
      &__item {
        &:first-child,
        &:last-child {
          margin-top: 40px;
        }

        width: 320px;
        height: max-content;
        padding: 30px;
        border-radius: 12px;
        &__top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;
          &__title {
            color: white;
            font-size: 20px;
            font-weight: 800;
          }
        }

        &__list {
          color: white;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: start;
          &__item {
            margin-bottom: 15px;
            font-size: 17px;
            font-weight: 500;
          }
        }
      }
    }
    &__bottom {
      margin-top: 22px;
      &__title {
        font-size: 45px;
        font-weight: 200;
        margin-bottom: 36px;
      }
      &__button {
        padding: 12px 20px;
        background-color: #ff3030;
        font-size: 22px;
        font-weight: 500;
        border-radius: 6px;
        cursor: pointer;
        & > a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}

@media #{$phone-landscape} {
  .solution_page {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // max-width: 1440;
    width: 80%;
    padding: 0px;
    width: 100%;
    &__content {
      padding: 10px;
      &__info {
        // display: flex;
        flex-direction: column;
        // align-items: center;
        &__image {
          width: 100%;
          //   & > img {
          //     width: 100%;
          //   }
        }
        &__text {
          width: 100%;
          padding: 30px;
          //   text-align: left;
          //   &__title {
          //     font-size: 35px;
          //     font-weight: 700;
          //     margin-bottom: 35px;
          //   }
          //   &__details {
          //     font-size: 20px;
          //     font-weight: 500;
          //   }
        }
      }
      &__cards {
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
        &__item {
          margin-top: 50px;
        }
      }
      &__bottom {
        margin-bottom: 75px;
      }
    }
  }
}

.card {
  // width: 45ch;
  // height: 45ch;
  // height: 10%;
  // background: grey;
  // border-radius: 5px;
  // background-image: url(https://drscdn.500px.org/photo/435236/q%3D80_m%3D1500/v2?webp=true&sig=67031bdff6f582f3e027311e2074be452203ab637c0bd21d89128844becf8e40);
  // background-size: cover;
  // background-position: center center;
  // box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  // transition: box-shadow 0.5s;
  // will-change: transform;
  // border: 15px solid white;
  width: max-content;
  height: max-content;
  background-color: #ff3030;
}

.card:hover {
  // box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
}
