@import "./styles/variable.scss";
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.content {
  padding-bottom: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media #{$phone-landscape} {
  .content {
    padding-bottom: 120px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}
