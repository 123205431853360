@import "../../styles/variable.scss";
.header {
  background-color: white;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 99;
  border-bottom: 1px black solid;
  &__content {
    padding: 0px 150px;
    width: 100%;
    max-width: 1440px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__top {
      &__logo {
        height: 100%;
        display: flex;
        max-height: 100px;
        width: 150px;
        & > svg {
          width: 100%;
        }
        &__img {
          height: 100%;
        }
      }
      &__menu_icon {
        display: none;
      }
    }
    &__menu {
      display: flex;
      & > div {
        font-size: 17px;
        font-weight: 500;
        margin-left: 56px;
        padding: 5px;
        display: flex;
        align-items: center;
        & > a.selected {
          color: #ff3030 !important ;
          font-weight: 700;
        }
        & > a {
          text-decoration: none;
          color: black;
        }
      }
    }
    & .signup_button {
      & > a {
        background-color: #ff3030;
        border-radius: 8px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 40px;
        font-weight: 700;
        font-size: 17px;
      }
    }
  }
}

@media #{$phone-landscape} {
  .header {
    padding: 0px;
    height: auto;
    display: flex;
    flex-direction: column;
    z-index: 99;
    &__content {
      padding: 0px;
      &__top {
        padding: 0px 25px;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px black solid;

        &__logo {
          margin: 5px;
          height: 30px;
        }
        &__menu_icon {
          display: block;
        }
      }
      &__menu {
        &.close {
          display: none;
        }
        &.open {
          position: absolute;
          top: 80px;
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 100%;
          background-color: white;
          border-bottom: 1px black solid;
          & > div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0;
            height: 50px;
            width: 80%;
            border-bottom: 1px rgb(121, 121, 121) solid;
            font-weight: 300;
            font-size: 20px;
            font-style: italic;

            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
      & .signup_button {
        padding: 40px 0px;
        & > a {
          background-color: white;
          width: 100%;
          color: #ff3030;
          font-weight: 800;
          font-size: 20px;
          &::after {
            margin-left: 10px;
            content: ">";
          }
        }
      }
    }
  }
}
