.submit_comp {
  height: 50px;
  width: 300px;
  border-radius: 5px;
  border: 0.5px solid gray;
  font-size: 20px;
  font-family: "Lucida Bright";
  background-color: #00a7b0;
  color: white;
}
