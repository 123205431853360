.input_comp {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > input {
    height: 50px;
    width: 300px;
    border-radius: 5px;
    border: 1px solid gray;
    font-size: 20px;
    font-family: "Roboto";
    text-indent: 15px;
  }
  & > textarea {
    height: 50px;

    min-width: 300px;
    max-width: 300px;
    min-height: 100px;
    border-radius: 5px;
    border: 1px solid gray;
    font-size: 20px;
    font-family: "Roboto";
    text-indent: 15px;
  }

  & .subfield {
    height: 20px;
    font-size: 15px;
    padding-top: 5px;
    font-weight: 400;
    color: rgb(196, 39, 0);
  }
}
