@import "../../styles/variable.scss";

.home {
  height: calc(100vh - 140px);
  position: relative;
  width: 100%;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  &__image {
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  &__content {
    width: 100%;
    padding-left: calc(calc(calc(100vw - 1440px) / 2) + 150px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;

    &__title {
      font-weight: 700;
      font-size: 30px;
      white-space: pre-wrap;
      margin-bottom: 16px;
    }
    &__details {
      font-weight: 400;
      font-size: 20px;
      margin-bottom: 36px;
    }
    &__button {
      // padding: 12px 22px;
      width: 175px;
      height: 50px;
      display: block;
      border-radius: 8px;
      font-weight: 500;
      border: 2px solid #ff3030;
      cursor: pointer;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 0%;
        height: 100%;
        background-color: #ff3030;
        position: absolute;
        transition: 1s;
      }
      &:hover {
        &::before {
          width: 100%;
          transition: 1s;
          color: white;
          z-index: -1;
        }
      }
      &__link {
        transition: 1s;
        &:hover {
          transition: 1s;
          color: white;
        }
        height: 100%;
        width: 100%;
        text-decoration: none;
        color: #ff3030;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media #{$phone-landscape} {
  .home {
    flex-direction: column-reverse;
    height: max-content;
    margin-bottom: 100px;
    &__image {
      width: 100%;
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 0px 30px;
      padding-top: 20px;
      text-align: center;
      margin: 0px;
      &__title {
        font-weight: 800;
        font-size: 27px;
      }
      &__details {
        font-size: 22px;
        font-weight: 400;
      }
      &__button {
        font-size: 18px;
      }
    }
  }
}
