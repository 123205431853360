@import "../../styles/variable.scss";

.contact_page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 50px;
  align-items: center;
  &__content {
    max-width: 1440px;
    padding: 150px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 150px;

    & > div {
      width: 100%;
    }
    &__left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      &__title {
        font-size: 40px;
        font-weight: 700;
        padding-bottom: 60px;
      }
      &__list {
        margin-left: 40px;
        &__item {
          display: flex;
          align-items: center;
          &:not(:last-child) {
            margin-bottom: 30px;
          }
          &__icon {
            display: block;
            margin-right: 20px;
            width: 30px;
            height: 30px;
          }
          &__value {
            font-size: 18px;
            text-align: start;
            width: 100%;
            font-weight: 600;
          }
        }
      }
    }
    &__right {
    }
  }
}

@media #{$phone-landscape} {
  .contact_page {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 50px;
    align-items: center;
    &__content {
      padding: 0px 30px;
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;

      & > div {
      }
      &__left {
        padding-bottom: 40px;
        &__title {
          font-size: 40px;
          font-weight: 700;
          padding-bottom: 60px;
        }
        &__list {
          margin-left: 40px;
          &__item {
            display: flex;
            align-items: center;
            &:not(:last-child) {
              margin-bottom: 30px;
            }
            &__icon {
              display: block;
              margin-right: 20px;
              width: 30px;
              height: 30px;
            }
            &__value {
              font-size: 18px;
              text-align: start;
              width: 100%;
              font-weight: 600;
            }
          }
        }
      }
      &__right {
      }
    }
  }
}
