@import "../../styles/variable.scss";

.product_page {
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  &__bar {
    position: sticky;
    top: 80px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    &__left,
    &__right {
      display: none;
    }
    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1440px;
      width: 100%;

      &__item {
        text-decoration: none;
        color: black;
        font-size: 18px;
        display: flex;
        align-items: center;
        &::before {
          content: "";
          height: 12px;
          width: 12px;
          display: block;
          background-color: #ff3030;
          border-radius: 50px;
          margin-right: 6px;
        }
      }
    }
    display: flex;
    height: 55px;
    border-bottom: 1px black solid;
    width: 100%;
    padding: 0px 150px;
  }
  &__content {
    margin-top: 50px;
    padding: 0px 150px;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__section {
      scroll-margin-top: 150px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &:not(:last-child) {
        margin-bottom: 100px;
      }
      &__title {
        font-size: 40px;
        font-weight: 900;
        margin-bottom: 50px;
        &::before {
          content: "# ";
          color: #ff3030;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        margin-bottom: 35px;
        &__row {
          display: grid;
          grid-template-rows: 1fr;
          column-gap: 100px;
          &:not(:last-child) {
            margin-bottom: 100px;
          }
          &__item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            text-align: start;

            &.single {
              display: grid;
              grid-template-columns: 1fr 1fr;
              justify-items: center;
              align-items: center;
              column-gap: 100px;
              & > img {
                width: 100%;
                height: 100%;
                justify-items: center;
                margin-bottom: 0px;
              }
              & > div {
                height: max-content;
              }
            }

            &__image {
              width: 50%;
              margin-bottom: 50px;
            }
            &__content {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              height: 100%;
              &__title {
                font-size: 28px;
                font-weight: 700;
                margin-bottom: 25px;
                text-align: center;
              }
              &__details {
                font-size: 19px;
                font-weight: 400;
                white-space: pre-wrap;
              }
            }
          }
        }
      }
    }
  }
}

@media #{(min-width:  1001px)} {
  .product_page__bar__content {
    transform: translateX(0px) !important;
    padding: 150px;
    max-width: 1440px;
  }
}

@media #{$phone-landscape} {
  .product_page {
    padding: 0px 0px;
    &__bar {
      padding: 0px;
      overflow: hidden;
      display: block;

      &__left,
      &__right {
        height: 100%;
        width: 100px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 20;
        background-color: #ff3030;
      }
      &__left {
        background: rgb(255, 255, 255);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 54%,
          rgba(255, 255, 255, 0.4514006286108193) 77%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      &__right {
        background: rgb(255, 255, 255);
        background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 54%,
          rgba(255, 255, 255, 0.4514006286108193) 77%,
          rgba(255, 255, 255, 0) 100%
        );
        right: 0px;
      }

      &__content {
        display: grid;
        height: 100%;
        grid-template-columns: repeat(5, 1fr);
        justify-items: center;
        width: 250vw;
        transition: 1s;
        max-width: none;
        &__item {
          &::before {
            display: none;
          }
        }
      }
    }
    &__content {
      padding: 0px 50px;
      padding-bottom: 70px;
      &__section {
        &__title {
          font-size: 30px;
          font-weight: 900;
        }
        &__content {
          &__row {
            display: flex;
            flex-direction: column;
            margin-bottom: 0px;
            &__item {
              margin-bottom: 50px;
              &.single {
                display: flex;
                & > img {
                  margin-bottom: 50px;
                }
              }

              &__image {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
