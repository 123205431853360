@import "../../styles/variable.scss";

.about_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
  padding: 50px 150px;
  &__info {
    display: flex;
    align-items: center;
    padding-bottom: 70px;
    &__image {
      width: 480px;
      margin-right: 50px;
      & > img {
        width: 100%;
      }
    }
    &__text {
      width: 516px;
      text-align: left;
      &__title {
        font-size: 35px;
        font-weight: 700;
        margin-bottom: 35px;
      }
      &__details {
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
  &__cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &__title {
      font-size: 40px;
      font-weight: 800;
      margin-bottom: 20px;
    }
    &__items {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &__item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: transparent;

        &__icon {
          width: 80px;
          height: 80px;
          border-radius: 100px;
          background-color: white;
          transform: translateY(+40px);
          display: flex;
          align-items: center;
          justify-content: center;
          // box-shadow: 0px -18px 64px -18px #c7c7c7;
          & > svg {
            height: 28px;
          }
        }
        &__content {
          box-shadow: 0px -10px 51px 0px rgba($color: #000000, $alpha: 0.1);
          border-radius: 12px;
          width: 330px;
          height: 220px;
          background-color: white;
          padding: 50px 24px 30px 24px;
          &__title {
            font-size: 20px;
            font-weight: 900;
            margin-bottom: 10px;
          }
          &__detail {
            font-size: 20px;
          }
        }
      }
    }
  }
  &__note {
    margin-top: 50px;
    text-align: start;
    font-weight: 400;
    font-size: 20px;
  }
}

@media #{$phone-landscape} {
  .about_page {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // max-width: 1440;
    width: 80%;
    padding: 20px;
    width: 100%;
    &__info {
      // display: flex;
      flex-direction: column;
      // align-items: center;
      &__image {
        width: 100%;
        //   & > img {
        //     width: 100%;
        //   }
      }
      &__text {
        width: 100%;
        padding: 30px;
        //   text-align: left;
        //   &__title {
        //     font-size: 35px;
        //     font-weight: 700;
        //     margin-bottom: 35px;
        //   }
        //   &__details {
        //     font-size: 20px;
        //     font-weight: 500;
        //   }
      }
    }
    &__cards {
      margin-bottom: 50px;

      &__items {
        align-items: center;
        flex-direction: column;
        &__item {
          margin-top: 20px;
        }
      }
    }
    &__note {
      margin-bottom: 120px;
    }
  }
}
