@import "../../styles/variable.scss";
.pricetable {
  // padding: 0px 10vw;
  // display: flex;

  max-width: 1500px;
  &__title {
    font-size: 40px;
    margin-bottom: 30px;
  }

  &__actions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 50px;
    &__switch {
      grid-column: 2;
      display: flex;
      align-items: center;
      & > .switch {
        margin: 0px 20px;
      }
    }
    &__currency {
      margin-top: 50px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      grid-column: 3;
      &__divider {
        height: 20px;
        margin: 0px 10px;
        border: 0.5px solid grey;
        background-color: grey;
      }
      &__item {
        font-size: 20px;
        color: grey;
        cursor: pointer;
        &.selected {
          color: black;
          font-weight: 600;
        }
      }
    }
  }

  &__table {
    display: flex;
    justify-content: space-between;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      width: 300px;
      border: 1px gray solid;
      padding: 25px;

      border-radius: 12px;

      &__title {
        font-size: 25px;
        padding-bottom: 30px;
      }
      &__image {
        height: 200px;
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        & > img {
          height: 100%;
          // width: 100%;
        }
      }
      &__price {
        padding-bottom: 30px;
        & > span {
          font-size: 45px;
          display: flex;
          padding-bottom: 10px;
          &::before {
            content: "$";
            font-size: 20px;
          }
        }
        &__sub {
          white-space: pre-wrap;
          color: gray;
          text-align: start;
          font-size: 15px;
        }
      }
      &__features {
        padding-left: 0px;
        &__item {
          color: black;
          font-size: 17px;
          text-align: start;
          list-style: none;
          margin-bottom: 10px;

          &__title {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 20px;
          }
          &__list {
            &__item {
              margin-bottom: 12px;
            }
          }
          &.listed {
            display: flex;
            list-style: none;
            background-color: #e1e1e1;
            padding: 20px;
            border-radius: 10px;

            color: black;
          }
        }
      }
    }
  }
}

@media #{$phone-landscape} {
  .pricetable {
    padding: 0px;
    &__title {
    }

    &__actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__switch {
        & > .switch {
        }
      }
      &__currency {
        display: flex;
        align-items: center;
        justify-content: center;
        &__divider {
        }
        &__item {
          &.selected {
          }
        }
      }
    }

    &__table {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__item {
        margin-bottom: 30px;
        width: 300px;
        &__title {
        }
        &__image {
          & > svg {
          }
        }
        &__price {
          & > span {
            &::before {
            }
          }
          &__sub {
          }
        }
        &__features {
          & > div {
          }
        }
      }
    }
  }
}
