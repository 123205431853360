@import "../../styles/variable.scss";
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: black;
  display: flex;
  justify-content: center;

  &__content {
    width: 100%;
    max-width: 1440px;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    padding: 0px 150px;
    justify-content: space-between;
    font-size: 17px;
    &__note {
    }
    &__actions {
      display: flex;
      align-items: center;
      &__links {
        & > a {
          color: white;
          text-decoration: none;
          &::after {
            content: "|";
            margin: 0px 10px;
          }
        }
      }
      &__soicals {
        margin-left: 5px;
        margin-top: 5px;
      }
    }
  }
}

@media #{$phone-landscape} {
  .footer {
    height: 120px;
    padding: 20px;

    &__content {
      padding: 0px 10px;
      flex-direction: column;
      margin-top: 10px;
    }
  }
}
